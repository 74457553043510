import {
    AmazonType,
    ApiType,
    AppConfiguration,
    AppType,
    AsignaturaType,
    EvaluacionTipoMap,
    HojaRespuestaType,
    PlataformaType
} from "@puntaje/shared/core"

export class BaseConfig implements AppConfiguration {
    navegacion = {
        urlHistorial: ["/evaluaciones/resultados/historial"]
    }

    generar = {
        generar: true,
        intrumentosRecomendados: true,
        pruebasPropias: false,
        buscador: true
    }

    registro = {
        enableCarreras: true,
        carrerasObligatorio: true,
        universidades: ["Universidad San Sebastián "],
        amount: 3,
        enableNivel: true,
        nivelObligatorio: true,
        enableColegio: true,
        colegioObligatorio: true,
        enableTelefono: true,
        telefonoObligatorio: true,
        nombresObligatorio: true,
        apellidoPaternoObligatorio: true,
        apellidoMaternoObligatorio: true
    }
    app = {
        assets: {
            logoLarge: "assets/img/logos/logoLarge.png",
            logoMedium: "assets/img/logos/logoMedium.png",
            logoSmall: "assets/img/logos/logoSmall.png",
            logoLargeLight: "assets/img/logos/logoLargeLight.png",
            logoMediumLight: "assets/img/logos/logoMediumLight.png",
            logoSmallLight: "assets/img/logos/logoSmallLight.png",
            logoSquare: "assets/img/logos/logoSquare.png",
            logoSquareLight: "assets/img/logos/logoSquareLight.png",
            streamingOffline: ""
        },
        name: "profesores",
        loader: "aprendolibre2",
        routeUser: null,
        routeUserInfo: null,
        paths: {
            pendingInfo: "/pending_info",
            landing: "/landing"
        },
        baseStyle: {
            navbar: {
                logo: "logoSmallLight",
                logoMobile: "logoSquareLight"
            },
            footer: {
                logo: "logoSmallLight",
                logosExtras: [
                    {
                        src: "assets/img/acreditacion.svg",
                        link: "https://www.uss.cl/",
                        title: "Acreditación"
                    }
                ]
            },
            landing: {
                backgroundImage:
                    "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/clones/nueva imagen landing USS.png",
                logo: "logoLargeLight",
                subtitle: "Prepara tu Prueba De Invierno con USS",
                subtitleHasShadow: null,
                logoFooter: "logoSmallLight"
            },
            sharedLayout: {
                backgroundImage:
                    "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/clones/nueva imagen landing USS.png",
                logo: "logoMediumLight"
            }
        },
        navigationByApp: {
            alumnos: [
                {
                    icon: "editar-prueba",
                    name: "Ejercita",
                    route: "/pruebas/generar_prueba"
                },
                {
                    icon: "prueba",
                    name: "Practica",
                    route: "/evaluaciones/recomendadas"
                },
                {
                    icon: "resultados",
                    name: "Resultados",
                    route: "/pruebas/resultados"
                },
                {
                    icon: "planificacion-o",
                    name: "Planes adaptativos",
                    route: "/planes_personales"
                },
                {
                    icon: "planificacion-o",
                    name: "Planes de clase",
                    route: "/plan_clases"
                },
                {
                    icon: "dialogos",
                    name: "Comunidad",
                    route: "/dudas_categorias"
                }
            ],
            profesores: [
                {
                    icon: "editar-prueba",
                    name: "Evaluar",
                    route: "/pruebas/generar_prueba"
                },
                {
                    icon: "resultados",
                    name: "Resultados",
                    route: "/evaluaciones/resultados"
                },
                {
                    icon: "megafono-o",
                    name: "Mis noticias",
                    route: "/noticias"
                }
            ]
        },
        menuConfig: {
            tipo: "horizontal"
        },
        showcasesByApp: {
            alumnos: [],
            profesores: []
        },
        informacionContacto: ["Si tienes consultas o comentarios escríbenos a contactouss@ogr.cl"]
    } as AppType

    amazon = {
        s3BucketUri: "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/",
        s3BucketUriNebu: "https://s3-us-west-1.amazonaws.com/bucket-nebuloso/"
    } as AmazonType

    api = {
        checkTokenPath: "/check_token",
        authPath: "/authenticate"
    } as ApiType

    asignaturas = [
        {
            asignatura: "Matemática",
            icono: "matematica",
            clase: "matematica",
            abreviacion: "Matemática",
            asignaturaPlataforma: "Matemática",
            evaluacionTipo: ["paes", "diagnostico paes"],
            materialVisible: true
        },
        {
            asignatura: "Lenguaje",
            icono: "lenguaje",
            clase: "lenguaje",
            abreviacion: "Lenguaje",
            asignaturaPlataforma: "Lenguaje",
            evaluacionTipo: ["paes", "diagnostico paes"],
            materialVisible: true
        },
        {
            asignatura: "Historia y Ciencias Sociales",
            icono: "historia",
            clase: "historia",
            abreviacion: "Historia y Ciencias Sociales",
            asignaturaPlataforma: "Historia y Ciencias Sociales",
            evaluacionTipo: ["paes", "diagnostico paes"],
            materialVisible: true
        },
        {
            asignatura: "Ciencias Naturales",
            icono: "ciencias",
            clase: "ciencias",
            abreviacion: "Ciencias Naturales",
            asignaturaPlataforma: "Ciencias Naturales",
            evaluacionTipo: ["paes", "diagnostico paes"],
            materialVisible: true
        }
    ] as AsignaturaType[]

    plataforma = {
        name: "PreuUSS",
        id: null,
        pais: "chile",
        lugarTipo: "Comuna",
        lugarLabel: "Comuna",
        identificadorUsuario: ["RUT"],
        identificadorEstablecimiento: ["rbd"],
        grupoUsuarioAlias: "curso",
        clasificacionTipoNivel: "curso",
        info: {
            social: {
                facebook: "https://web.facebook.com/ComunidadUSS/",
                twitter: "https://twitter.com/USanSebastian",
                youtube: "https://www.youtube.com/channel/UCVMqFpVkFP6CG2iai6sEOvg",
                instagram: "https://www.instagram.com/admisionusansebastian/ "
            },
            soporte: {
                mail: "contactouss@ogr.cl",
                telefono: null
            },
            contacto: {
                mail: "Si no puedes acceder escríbenos a contactouss@ogr.cl",
                telefono: ""
            },
            companyName: "Universidad San Sebastián",
            companySite: "https://www.uss.cl/"
        },
        evaluacionTipoAdaptiveDefault: "diagnostico paes",
        evaluacionDefault: "paes",
        resumenGeneral: {
            curriculum: "curricular",
            generadores: [83, 15, 16, 17, 18, 84, 19, 20, 85]
        },
        tiposInstrumentoInformeUso: [],
        evaluacionTipos: ["paes", "diagnostico paes"],
        añoInicial: 2020,
        periodos: [
            {
                label: "",
                fechaInicial: [0, 1],
                fechaFinal: [11, 31, 23, 59, 59]
            }
        ],
        streamingDefault: null,
        iconSet: {
            noticiasShowcase: "megafono-o",
            establecimientosShowcase: "establecimiento-o",
            cursosShowcase: "comunidad-o"
        },
        instrumentoMensualTipo: "curricular",
        tiposNotificacion: [
            {
                tipo: "curricular mexico",
                label: "Pruebas"
            },
            {
                tipo: "duda",
                label: "Dudas"
            },
            {
                tipo: "respuesta_correcta_duda",
                label: "Respuestas Correctas Dudas"
            },
            {
                tipo: "respuesta_duda",
                label: "Respuestas Dudas"
            },
            {
                tipo: "retroalimentacion",
                label: "Retroalimentaciones"
            }
        ],
        sesionAlias: "logoSquareLight",
        identificadorUsuarioAlias: null,
        canTextHighlight: true,
        labelHighlight: "Resaltar Texto",
        enableAchievements: true,
        locale: "es-cl",
        evaluacionResponder: "estandar",
        disableZopim: true,
        realizarButton: "Generar Prueba",
        descargarButton: "Descargar",
        customGenerateTitle: "Generar Prueba"
    } as PlataformaType

    evaluaciones = {
        paes: {
            alias: "PAES",
            cantidadPreguntas: 5,
            clasificaciones: {
                estadisticas: ["Eje PAES"],
                estadisticasEvolutivas: ["Eje PAES"],
                desempenoEvaluacion: "Eje PAES",
                groupBy: ["Eje PAES,Unidad Temática PAES"],
                clasificacionTiposTablaEspecificaciones: ["Eje PAES", "Unidad Temática PAES"],
                methods: "clasificaciones",
                referencesTo: {
                    "Unidad Temática PAES": "Eje PAES"
                },
                configuration: [
                    {
                        name: "Eje PAES",
                        htmlId: "Eje_PAES",
                        hasHijos: true,
                        clasificacionTipo: "Eje PAES"
                    }
                ],
                cambiarPregunta: ["nivel 1 prueba de transición"]
            },
            clasificacionDistribucion: "ejes",
            clasificacionMaterial: {
                clasificacion: "Eje PAES",
                label: "eje"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "Eje PAES",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "Unidad Temática PAES",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: false
                },
                {
                    nombre: "Habilidad PAES",
                    alumnos: false,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                }
            ],
            cursoTipo: "curso",
            cursos: [
                {
                    label: "7º Medio",
                    clasificacion: "Séptimo Básico"
                },
                {
                    label: "8º Medio",
                    clasificacion: "Octavo Básico"
                },
                {
                    label: "1º Medio",
                    clasificacion: "Primero Medio"
                },
                {
                    label: "2º Medio",
                    clasificacion: "Segundo Medio"
                },
                {
                    label: "3º Medio",
                    clasificacion: "Tercero Medio"
                },
                {
                    label: "4º Medio",
                    clasificacion: "Cuarto Medio"
                }
            ],
            cursosMaterial: [],
            estrellas: [
                {
                    max: 266,
                    min: 150
                },
                {
                    max: 383,
                    min: 267
                },
                {
                    max: 500,
                    min: 384
                },
                {
                    max: 617,
                    min: 501
                },
                {
                    max: 734,
                    min: 618
                },
                {
                    max: 850,
                    min: 735
                }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            rangoCalificacion: {
                min: 150,
                tickInterval: 50,
                max: 850
            },
            streamingChannelAlias: "Clases PAES",
            planPersonalClasificacionMateriales: []
        },
        "diagnostico paes": {
            clasificacionMaterial: {},
            planPersonalClasificacionMateriales: [],
            clasificacionTiposEstadisticas: [],
            cursos: [],
            cursosMaterial: [],
            estrellas: [],
            nivelTipoInstrumentoInformeFinal: [],
            rangoCalificacion: {
                min: 100,
                tickInterval: 50,
                max: 1000
            },
            clasificaciones: {
                estadisticas: [],
                groupBy: [],
                clasificacionTiposTablaEspecificaciones: [],
                referencesTo: {},
                configuration: [],
                cambiarPregunta: [],
                methods: "clasificaciones",
                desempenoEvaluacion: "Eje PAES,Unidad Temática PAES"
            }
        }
    } as EvaluacionTipoMap

    instrumentosPredefinidos = {
        zonas: ["Columna 1", "Columna 2", "Columna 3"]
    }

    hojaRespuesta = {
        sectores: [
            [1, 2],
            [2, 3],
            [3, 4],
            [5, 2],
            [2, 7],
            [7, 4],
            [5, 6],
            [6, 7],
            [7, 8]
        ],
        rutFormaByMarkers: {
            10: {
                rutMarkerUp: 6,
                rutMarkerBottom: 10,
                rutCols: 11,
                formaMarkerUp: 9,
                formaMarkerBottom: 6,
                formaCols: 11
            }
        },
        alignByMarkers: {
            10: [1, 5]
        },
        validMarkers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        alternativas: 5
    } as HojaRespuestaType

    mensajesLogin = {
        idPlaceholder: "Email o RUT",
        invalidLoginId: "Debes incluir un correo electrónico o RUT válido.",
        invalidLoginMessage1:
            "<strong>La combinación de correo electrónico/contraseña o RUT/contraseña es incorrecta.</strong> Las contraseñas de ",
        invalidLoginMessage2:
            ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús". También puedes probar a borrar el caché y las cookies de tu navegador.'
    }
}
