<div class="content-wrap">
    <div class="content-container bottom-margin">
        <div class="caja">
            <div class="fading-image" [lazyLoad]="landingImage" [offset]="200"></div>
            <div class="main-content">
                <h2 class="titulo" *ngIf="titulo">{{ titulo }}</h2>
                <div class="shared-content-over-title" #sharedLayoutContentOverTitle>
                    <l-slot name="content-over-title"></l-slot>
                </div>
                <div class="offmargin-padding"></div>
                <div class="container-fluid content-subcontainer">
                    <h1 class="content-title">
                        <l-slot name="titulo"></l-slot>
                    </h1>
                    <div class="shared-content" [class.logged-layout-contenido]="showContenido" #loggedLayoutContenido>
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <footer>
        <img
            class="footer-logo"
            src="{{ logoFooter }}"
            alt="{{ config.plataforma.info.companyName }}"
            title="{{ config.plataforma.info.companyName }}"
        />
        <div class="footer-col">
            <h3 class="contactanos">Contáctanos</h3>
            <ul>
                <li>
                    <p>
                        <fa name="envelope-o"></fa>
                        &nbsp;{{ config.plataforma.info.contacto.mail }}
                    </p>
                </li>
            </ul>
        </div>
        <ng-container *ngFor="let logo of logosExtra">
            <a href="{{ logo.link }}" class="acreditacion">
                <img class="footer-logo" src="{{ logo.src }}" alt="{{ logo.title }}" title="{{ logo.title }}" />
            </a>
        </ng-container>
    </footer>
</div>
