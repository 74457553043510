import { ChangeDetectorRef, Component, Directive, TemplateRef, ViewChild, Input } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
import { queueScheduler, Subscription } from "rxjs"
import { observeOn } from "rxjs/operators"
import { SharedLayoutService } from "@puntaje/shared/cui"
declare const config: AppConfig
/*#################################

Template simple para páginas para usuario sin tipo de usuario en particular.
Implementa una sección de contenido y un título.

shared-layout-titulo
shared-layout-contenido

#################################*/

@Component({
    templateUrl: "shared_layout.component.html",
    selector: "shared-layout",
    styleUrls: ["shared_layout.component.scss"]
})
export class SharedLayoutComponent {
    config: typeof config = config
    logo: string
    landingImage: string = "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/shared/base/fondo_base.jpg"
    showContenido: boolean
    logoFooter: string
    logosExtra: any[]
    titulo: string

    sub: Subscription
    subTemplate: Subscription

    templateObj: { [name: string]: TemplateRef<any> } = {}

    constructor(protected cdr: ChangeDetectorRef, protected sharedLayoutService: SharedLayoutService) {
        this.titulo = this.config.app.baseStyle?.landing?.subtitle ? this.config.app.baseStyle?.landing?.subtitle : ""
        this.landingImage = this.config.app.baseStyle?.sharedLayout?.backgroundImage ?? this.landingImage
        this.logo = this.config.app.baseStyle?.sharedLayout?.logo
            ? this.config.app.assets[this.config.app.baseStyle?.sharedLayout?.logo]
            : this.config.app.assets.logoLargeLight
        this.logoFooter = this.config.app.baseStyle?.landing?.logoFooter
            ? this.config.app.assets[this.config.app.baseStyle?.landing?.logoFooter]
            : this.config.app.assets.logoMediumLight
        this.logosExtra = this.config.app.baseStyle?.footer?.logosExtras
            ? this.config.app.baseStyle?.footer?.logosExtras
            : []
    }

    ngOnInit(): void {
        this.sharedLayoutService.resetSlots()
        this.sharedLayoutService.setOptions({})

        this.subTemplate = this.sharedLayoutService.templates$
            .pipe(observeOn(queueScheduler))
            .subscribe(templateObj => {
                this.templateObj = templateObj

                this.cdr.detectChanges()
            })
    }
}
